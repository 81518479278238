import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { Location } from '@reach/router';
import { getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FadeIn from 'react-fade-in';
import dayjs from 'dayjs';
import ShareButtons from '../components/ShareButtons';
import Layout from '../components/Layout';
import { colors } from '../styles/colors';
import { logos } from '../data/logos';

// import image from '../images/me3.jpg';

import MainPhoto from '../components/MainPhoto';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SubContainer = styled.div`
  max-width: 90vw;
  margin-top: 0;
  padding: 0 5rem;

  @media (max-width: 1100px) {
    margin: 0 3rem;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin: 0px auto;
  }

  @media (max-width: 420px) {
    margin: 0px auto;
    padding: 0;
  }

  h3,
  h1 {
    margin-top: 4rem;

    @media (max-width: 768px) {
      margin-top: 2rem;
    }
  }

  h4 {
    font-weight: 300;
    padding-bottom: 5px;
  }

  .photo {
    width: 400px;
    height: 450px;
    z-index: 500;
    background-color: ${colors.darkPurple};
    padding-bottom: 0px;

    @media (max-width: 768px) {
      top: 8rem;
      left: 2rem;
      width: 200px;
      height: 220px;
    }

    @media (max-width: 420px) {
      width: 90vw;
      height: 25vh;
      position: relative;
      border: none;
      top: 0px;
      left: 0px;
      overflow: initial;
      margin: 10px 0;
      margin-top: 20px;
    }
  }

  .facts {
    margin-top: -350px;
    padding: 30px;
    padding-top: 5px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3),
      -10px -10px 50px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin-left: 18rem;

    @media (max-width: 768px) {
      margin-top: 100px;
      padding: 20px;
      margin-left: -12rem;
      margin-right: 30px;
    }

    @media (max-width: 420px) {
      margin: 0px;

      padding-left: 0px;
      box-shadow: none;
      background-color: transparent;
      backdrop-filter: none;
    }
  }

  .facts > h1 {
    line-height: 34px;
    color: #07f646;
  }

  .buttonsSection {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    ${
  '' /* @media (max-width: 420px) {
            justify-content: space-between;
        } */
}
  }

  @media (max-width: 768px) {
    .facts > h1 {
      margin-top: 204px;
      margin-left: 2rem;
    }
    .buttonsSection {
      margin-top: 1rem;
    }
  }

  @media (max-width: 420px) {
    .facts > h1 {
      margin-left: 1rem;
    }
  }

  button {
    padding: 10px 20px;
    border: 1px solid ${colors.fuchsia};
    border-radius: 10px;
    background-color: transparent;
    color: ${colors.defaultColor};
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    @media (max-width: 1100px) {
      font-size: 0.8rem;
    }

    @media (max-width: 420px) {
      padding: 7.5px 15px;
    }
  }

  .description,
  .subSection {
    padding-left: 4rem;
    @media (max-width: 768px) {
      padding-left: 1rem;
    }
    @media (max-width: 420px) {
      padding-left: inherit;
    }
  }

  .description a {
    color: inherit;
  }

  .teckStackSection {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .techStack {
    border-radius: 0.7rem;
    display: flex;
    justify-content: flex-start;
    padding: 0px;
    padding-right: 20px;
    padding-top: 20px;
  }

  .logo img {
    height: 45px;
    margin-top: 4px;
    padding-right: 5px;
  }

  .name {
    margin: auto 5px;
    font-size: 1.2rem;
  }
  .smIcons {
    margin-right: 10px;
    color: #ccc;
  }
  .location {
    margin-left: 20px;
  }
  .techStackSectionTitle {
    border-bottom: 1px solid ${colors.green};
  }
  .designSectionTitle {
    border-bottom: 1px solid ${colors.fuchsia};
  }
`;

function BlogItem({ data }) {
  const fm = data.directus.blogPosts[0];
  const {
    seo, image, content, date, subTitle,
  } = fm;
  const bgImage = getImage(image.imageFile);
  const formattedDate = dayjs(date).format('dddd, MMMM D YYYY');
  return (
    <Location>
      {({ location }) => {
        const url = location.href;
        return (
          <Layout seo={seo} article>
            <FadeIn>
              <Container>
                <SubContainer>
                  <div className="photo">
                    <MainPhoto image={bgImage} width="100%">
                      <div className="date_string">{formattedDate}</div>
                      <div className="buttonsSection">
                        {/* \ */}
                        <Link to="/blog">
                          <button className="button live grow">
                            <FontAwesomeIcon icon={logos.back} size="1x" />
                            {' '}
                            Back
                          </button>
                        </Link>
                      </div>
                    </MainPhoto>
                  </div>

                  <div className="facts">
                    <h1>{subTitle}</h1>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'right',
                        marginTop: 32,
                      }}
                    >
                      <ShareButtons
                        title={seo.title}
                        url={url}
                        twitterHandle={null}
                      />
                    </div>
                  </div>
                </SubContainer>
              </Container>
            </FadeIn>
          </Layout>
        );
      }}
    </Location>
  );
}

export const blogItemPageQuery = graphql`
  query blogItemPageQuery($slug: String!) {
    directus {
      blogPosts(filter: {slug: {_eq: $slug}}) {
        id
        subTitle
        image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 512
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        slug
        date
        content
        seo {
          title
          description
          image {
            id
          }
        }
      }
    }
  }
`;

export default BlogItem;
